.process-vertical {
    .banner-strap & {
        color: $white;
    }
    li:not(:last-child):after {
        height: calc(100% - 5rem) !important;
    }
    font-weight: bold;

    .history_title {
        @extend .mb-0;
        @extend .text-primary;
        // @extend .px-4;
    }

    .history_description {
        @extend .mb-0;
        // @extend .px-4;
    }
    li:nth-child(odd) {
        text-align: left;
    
    }
    li {
        align-items: baseline;
    }
    li:not(:last-child) {
        padding-bottom: 2.5rem;
    }
}