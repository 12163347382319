.latestNews {
    @extend .container;

    .card {
        @extend .card-body;
        @extend .overlay;
        @extend .border-0;
        @extend .text-light;
        @extend .mb-md-0;
        @extend .justify-content-end;

        // Border Radius for card
        &:after {
            border-radius: $border-radius !important;
        }
    }

    .badge {
        @extend .mr-1;
        @extend .mb-2;
        border: 2px solid theme-color('primary-2');
        background: white;
        // #1F9EE5;
        @extend .badge-light;
    }

    .itemLarge {
        @extend .col-md-7;
        @extend .col-lg-8;
        @extend .d-flex;

        .badge {
            @extend .badge-light;
            border: none;
        }

    }

    .itemsSmall {
        ul {
            @extend .list-articles;
            @extend .list-unstyled;
        }
        @extend .col-md-5;
        @extend .col-lg-4;

        h6 {
            color: theme-color('primary');

        }

        // .badge {
        //     @extend .bg-primary;
        //     @extend .text-light;
        // }
    }
    .nav-item {
        @extend .row;
        @extend .row-tight;
    }
}
