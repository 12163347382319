.accordion {
    .border-top {
        border-color: theme-color('primary') !important;
    }

    .accordion-panel-title {
        @extend h3;
        // @extend .pt-2;
        color: theme-color('primary');
        path {
            fill: theme-color('primary-2');
        }
    }

    .accordion-content {
        border-color: theme-color('primary') !important;

    }

}
