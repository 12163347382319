.header-section {
    @extend .header-inner;
    @extend .p-0;
    @extend .jarallax;
    @extend .position-relative;

    @extend .bg-primary;

    @extend .o-hidden;

    .header-content {
        @extend .container;
        @extend .py-0;

        .row {
            @extend .layer-2;
            @extend .my-4;
            @extend .my-md-6;
            min-height: 250px;

            .noBg &{
                @extend .mb-3;
            }

        }
    }


    h1 {
        color: white;
        @extend .display-4;
        @extend .mb-0;
    }


    &.story {
        // border: 2px solid  red;
        @extend .pt-6;
        @extend .mb-0;
        @extend .bg-white;
        &:before {
            display: none;
        }

        h1 {
            color: theme-color('primary');
        }

        .header-content {
            min-height: initial;
        }
    }


    p {
        &.h1 {
            @extend h1;
            color: theme-color('primary-2') !important;
        }
    }

    .lead {
        @extend .mb-0;
    }

    div[id*="jarallax-container"] {
        // mix-blend-mode: multiply;
    }

    &:before {
        content: '';
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // mix-blend-mode: multiply;
        // background: theme-color('primary') !important;
        background: linear-gradient(360deg, rgba(theme-color('primary'), .85) 0%, rgba(theme-color('primary'), 1) 100%);
    }

    .btn {
        // font-size: 1.25em !important;
        @extend .mt-4;
        background: none;
        color: white;
    }
    // .bottomHeader {
    //     position: absolute;
    //     bottom: 0;
    // }


}
