body {
    background-color: #fff;
}

.wrapper {
    // padding-top: 80px;
    background-color: white;
    margin: 0 auto;

    max-width: 1440px;
    width: 100%;
    // padding-bottom: 3em;
}

main {
    @extend .py-4;
}

.bg-white {
    background-color: white;
}


.color-primary {
    color: theme-color('primary');
}

.banner-strap {
    // background-color: $color-primary;
    // @extend .text-white;
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: theme-color('primary-3');
}



.noBg {
    .navbar {
        &.bg-white {
            background: #EFEFEF !important;
        }
    }

    .breadcrumb {
        background: #EFEFEF;
    }
    main {
    @extend .py-4;
    }

}

figure {
    margin: 0 0 1rem;
}
