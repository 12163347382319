// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.


// Color Scheme
// Uncomment the variables below and change the hex values.

$primary:      #13357A;
$primary-2:     #7AB0E0;
$primary-3:     #FFF;
// $primary-4:     #e2f0cd;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);

$theme-colors: (
    "primary":    $primary,
    "primary-2":  $primary-2,
    "primary-3":  $primary-3,
);

$border-radius: 20px;
$border-width: 2px;
$border-color: $primary;

$font-family-sans-serif:      'Inter', sans-serif;
$font-family-base:            'Inter', serif;
$headings-font-family:        'Inter', sans-serif;

$font-weight-light:           400;
$font-weight-normal:          400;
$font-weight-medium:          600;
$font-weight-bold:            700;

$headings-font-weight:        700;
$display1-weight:             600;
$display2-weight:             600;
$display3-weight:             600;
$display4-weight:             600;

