footer {
    @extend .d-flex;
    @extend .pb-3;
    @extend .pt-0;

    .container {
        @extend .d-flex;
        @extend .flex-column;
    }

    min-height: 350px;
    @include media-breakpoint-down(md) {
        min-height: 250px;
    }

    .divider {
        transform: scaleX(-1);
    }

    .navbar-brand {
        font-weight: $font-weight-bold;
    }

    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
    color: white !important;

    .footer-nav {
        @extend .nav;

        .nav-item {
            &:last-child {
                a {
                    @extend .pr-0;
                }
            }
            @include media-breakpoint-down(md) {
                .nav-link {
                    padding-left: 0;
                }
            }
            @include media-breakpoint-down(sm) {
                display: block;
                width: 100%;
                border-top: 1px solid $primary-2;
                .nav-link {
                    padding-left: 0;
                }
            }
        }
    }
    .navbar-brand {
        @include media-breakpoint-down(sm) {
        margin-bottom: 1rem;
        }
    }
    .social-nav{
        @include media-breakpoint-down(sm) {
            margin-top: 1rem;
        }
        .nav-item {
            @include media-breakpoint-down(sm) {
            width: 2em;
            margin-right: 1em;
            padding-left: 0;
            }
        }
    }

    .meta-nav {
        @extend .nav;
        @extend .flex-column;
        .nav-link {
            @extend .pb-0;
        }
    }

    .social-nav {
        @extend .nav;

        .nav-item {
            @extend .col-sm-3;

            a {
                background: white;
                display: block;
                width: 2em;
                height: 2em;
                color: theme-color('primary');
                position: relative;

                i {
                    margin: 0;
                    font-size: 1.5em;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-right: -50%;
                    transform: translate(-50%, -50%)
                }

                &:hover {
                    background: theme-color('primary-2');
                }
            }
        }
    }

    a {
        font-weight: $font-weight-normal;
        color: white;

        &:hover {
            color: white;
            text-decoration: underline;
        }
    }

    .nav {

        .link-app,
        .nav-link {
            color: white !important;
            font-size: 1em;
            opacity: 1 !important;
            font-weight: 600;
        }
    }

    .font-regular {
        font-family: $font-family-base;
        font-size: 14px;
        font-weight: $font-weight-normal;

        .nav-link {
            font-weight: $font-weight-normal !important;
            font-size: 14px;
        }
    }

    // background-color: theme-color('primary');

    // @extend .bg-verlauf;

    // @Julien, das wird wegen der Grafik oben evtl. nicht gehen.
    // background-image: linear-gradient(225deg, theme-color('primary'), theme-color('primary-1'));
}
