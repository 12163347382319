.btn {
    @extend p;
    border-radius: 6px !important;
    font-family: $headings-font-family;
    letter-spacing: 1px;
    font-weight: 600;
    // text-transform: uppercase;

    &:hover {
        cursor: pointer;
    }

}

.navbar, .header-content {
    .btn {
        @extend .btn-primary;
        border: 2px solid theme-color('primary-2');
        background: none;
        color: theme-color('primary');

        &:hover{
            border-color: theme-color('primary-2');
            background: theme-color('primary-2');

        }
    }
}