$dimensions: 18em;


.stoerer {

    
    position: absolute;
    z-index: +2;
    top: 16% !important;
    right: 7% !important;
    width: $dimensions;
    height: $dimensions;
    border-radius: $dimensions/2;
    @extend .d-none;
    @extend .d-lg-block;
    // padding: 1em;
    // border: 2px solid;
    // background: $white;
    // transform: rotate(-5deg);

    @include media-breakpoint-down (lg){
        padding: 1.5em;
        right: 0 !important;
    }

    p {
        @extend .mb-0;
    }

    .text-center {
        width: 100%;
    }
}

