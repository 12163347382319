form {
    fieldset {
        @extend .form-row;

        .clearfix {
            @extend .col-12;
        }

        input,
        textarea {
            @extend .form-control;
        }

        label {
            margin: 1em 0 0;
            font-size: 0.85em;
        }
    }

    .error {

        input,
        textarea {
            border-color: $red;
        }

        .help-inline {
            color: $red;
            font-size: 0.85em;
        }
    }

    .form-navigation {
        ul {
            list-style-type: none;
            padding: 1.5em 0.5em 0;
        }
    }
}

// .mauticform-innerform {
//     @extend .container;
// }


// .mauticform-page-wrapper {
//     @extend .row;
// }

form {}

.mauticform-page-wrapper {
    display: flex !important;
    @extend .row;
}

.mauticform-text,
.mauticform-email,
.mauticform-tel,
.mauticform-select,
.mauticform-button-wrapper {
    @extend .form-group;

    input,
    textarea {
        @extend .form-control;
    }

    .btn-default {
        @extend .btn-primary;
    }
}

.mauticform-select {
    select {
        @extend .custom-select;
    }
}

.mauticform-errormsg {
    color: $red;
    font-size: 0.75em;
}

.mauticform-has-error {
    input {
        border-style: dashed !important;
    }
}

