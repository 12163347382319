.navbar-container {

    .navbar-dark.scrolled {
        background-color: theme-color('primary') !important;
    }

    font-family: $headings-font-family;
    font-size: 1em !important;
    letter-spacing: .5px;

    .navbar-toggler {
        .icon {
            height: 3rem;
        }
    }

    .first-level {
        &.nav-link {
            color: theme-color('primary-2');

            // &:hover {
            //     color: #f09;
            // }
        }
    }

    .nav-item {
        a {
            &.nav-link {
                font-weight: $font-weight-bold;
                white-space: nowrap;
                color: theme-color('primary-2');

                &.is-active,
                &.is-currend {
                    color: white;
                }

                // &:hover {
                //     color: theme-color('primary');
                // }
            }
        }

        &:hover,
        &.show {
            a {
                &.nav-link {
                    color: white;
                }
            }
        }

        .icon {
            display: none;
        }

        .dropdown-grid-menu {
            border-radius: 0;
            border: none;
        }
    }


    .dropdown-item {
        font-weight: $font-weight-bold;

        .icon {
            display: initial;
        }

        color: theme-color('primary-2');

        span {
            border-bottom: none;
        }

        &.active,
        &:hover {
            color: theme-color('primary');
        }
    }

    .is-active,
    .is-current {
        color: white !important;

        .bordered {
            @extend .pb-3;
            // border-bottom: 2px solid theme-color('primary-2');
        }

        // color: theme-color('primary') !important;

        &.dropdown-item {
            color: theme-color('primary') !important;
        }

    }

    .first-level {
        svg {
            transform: rotate(-90deg);
        }
    }

    .btn {
        position: relative;
        top: .75em;
        color: $white;
    }
}

.noBg {
    .navbar-container {

        .is-active,
        .is-current {
            color: theme-color('primary') !important;
        }

        .navbar-brand {
            color: theme-color('primary') !important;
        }

        .btn {
            position: relative;
            top: .75em;
            color: theme-color('primary') !important;
        }
    }


    .nav-item {

        &:hover,
        &.show {
            a {
                &.nav-link {
                    color: theme-color('primary');
                }
            }
        }
    }

}





.navbar-brand {
    font-weight: $font-weight-bold;

    img {
        // margin-right: 0.5em;
        // margin-top: 0.5em;
        // margin-bottom: 0.5em;
        transition: .3s;
        height: 70px;
        max-width: 298px;

        @include media-breakpoint-down(md) {
            height: 55px;
            margin-top: .5em;
        }

        @include media-breakpoint-down(sm) {
            height: 40px;
        }

        .scrolled & {
            height: 50px;
            margin: 0 .75em 0 0;
            transition: .3s;

            @include media-breakpoint-down(sm) {
                height: 40px;
            }
        }
    }

}

.utility {
    @extend .py-1;
    @extend .bg-primary-2;
    font-weight: $font-weight-bold;
    font-size: 0.75em;

    .dropdown {}

    .dropdown-menu {
        @extend .p-1;
        font-size: 1em;
        border: none;
        border-radius: 0 !important;
    }

    .dropdown-item {
        @extend .p-0;
        width: auto;
    }
}

.navToggle {
    background-color: none;

    svg {
        transform: rotate(-90deg);
    }
}

.navbar-toggled-show {
    background: theme-color('primary') !important;
    .noBg & {
        background: theme-color('primary-3') !important;
    }

}

    .navbar-toggler {
        .noBg & {
        svg path {
            fill: theme-color('primary') ;
        }
    }
    }

    button {
        &.navbar-toggler {
            border: 2px solid theme-color('primary-2') !important;
            border-radius: .325em;
        }

        &:focus{

            outline-color: none !important;

        }
        .navbar-toggler-open, .navbar-toggler-close{
            padding: .5rem;
          }
    }
