.tab-element {
    .nav {
        @extend .pb-2;
        @extend .border-bottom;
        border-color: theme-color('primary') !important;
        @extend .justify-content-center;
    }

    .nav-item {
        @extend .mx-1;
        @extend .text-center;

        .eq {
            height: 84px;
        }

        .label {
            font-family: $font-family-sans-serif;
            font-weight: $font-weight-bold;
            font-size: 1em;
            color: theme-color('primary') !important;
        }
    }

    .tab-content {
        @extend .mt-4;
        border-color: theme-color('primary') !important;
    }

    .nav-link {
        filter: grayscale(80%);
        opacity: 0.3 !important;
        transition: all 0.5s ease-out;

        &.active,
        &:hover {
            filter: brightness(100%);
            opacity: 1 !important;
        }
    }
}
